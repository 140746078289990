import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/video'
  },
  {
    name: 'video',
    path: '/video',
    component: () => import('./view/video'),
    meta: {
      title: '运维视频',
    },
  },
  {
    name: 'box',
    path: '/box',
    component: () => import('./view/box/index.vue'),
    meta: {
      title: '箱安装进度查询',
    },
  },
  {
    name: 'box2',
    path: '/box2',
    component: () => import('./view/box/index2.vue'),
    meta: {
      title: '箱安装进度查询',
    },
  },
  {
    name: 'boxinformation',
    path: '/boxinformation',
    component: () => import('./view/box/information'),
    meta: {
      title: '箱信息',
    },
  },
  {
    name: 'boxinformation21',
    path: '/boxinformation21',
    component: () => import('./view/box/information21'),
    meta: {
      title: '箱信息查询',
    },
  },
  {
    name: 'boxinformation22',
    path: '/boxinformation22',
    component: () => import('./view/box/information22'),
    meta: {
      title: '箱信息详情',
    },
  },
  {
    name: 'privacy_agreement',
    path: '/privacy_agreement',
    component: () => import('./view/app/privacy_agreement'),
    meta: {
      title: '隐私协议',
    },
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
